<template>
    <layout class="wrapper infomation-detail">
        <!-- 面包屑 -->
        <div class="container infomation-mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/infomation-list/'+$route.params.id }">热点资讯</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>资讯详情</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container flex">
            <div class="guide-list-left mt20">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article mt10 t-l pb20" v-if="infomation">
                        <p class="fs22 strong color333 row-2">{{ infomation.news_title }}</p>
                        <p class="fs12 color999 row-1 mt20">
                            <span>{{ infomation.create_time }}</span>
                            <span class="ml20" v-if="infomation.source"><span class="strong">来源：</span>{{
                                infomation.source
                            }}</span>
                            <span class="ml20" v-if="infomation.browsing"><span class="strong">阅读：</span>{{
                                infomation.browsing
                            }}次</span>
                        </p>
                    </div>
                    <div v-if="infomation.news_content" v-html="infomation.news_content" class="t-l mt15 content-art mb20"
                        @click="handleFollowEvent($event)"></div>
                    <!--热点资讯点赞收藏  -->
                    <div v-if="type == 2 && infomation.news_content" class="flex x-right">
                        <span class="ml15 cursor" @click="getExeLikeCollect('2')">
                            <i :class="['med', infomation.is_like == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                            <span class="ml5">{{ infomation.is_like == 1 ? '已点赞' : '点赞' }}</span>
                        </span>
                        <span class="ml15 cursor" @click="getExeLikeCollect('1')">
                            <i :class="['med', infomation.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                            <span class="ml5">{{ infomation.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                        </span>
                    </div>
                    <a-empty class="mb10" style="margin-top:100px;" v-if="!infomation.news_content && !loading" />
                    <!-- 相关资讯 -->
                    <div class="correlation-box mb20" v-if="correlationList.length && correlationList.length > 1">
                        <div class="correlation-bg t-l">
                            <span class="correlation-title color000 strong mb15">相关资讯</span>
                            <template v-for="(corr_item, corr_index) in correlationList">
                                <router-link
                                    :to="{ path: '/special-ma/infomation-detail/'+$route.params.id, query: { id: corr_item.id, type: corr_item.type } }"
                                    :key="corr_index" class="row-1 mb10 strong color333" v-if="corr_item.id != newId">{{
                                        corr_item.news_title }}</router-link>
                            </template>
                        </div>
                    </div>
                    <div v-if="pre_news && pre_news?.id && pre_news?.is_show == '1'"
                        @click="handleNextNews(pre_news.id)" class="t-l mt10 color333 row-1">上一条：<a
                            class="strong color333">{{ pre_news.news_title }}</a></div>
                    <div v-if="next_news && next_news?.id && next_news?.is_show == '1'"
                        @click="handleNextNews(next_news.id)" class="t-l mt10 color333 row-1">下一条：<a
                            class="strong color333">{{ next_news.news_title }}</a></div>
                    
                </a-spin>
            </div>
            <div class="guide-list-right mt25">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>

    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import { urlReplace } from '@/utils/urlReplace';
import * as videoObject from '@/components/video-player/video-play';
import { expertJumpDetail } from '@/utils/jumpPageMethods'
import { mapState } from 'vuex';
import { committee_id } from '@/config';
export default {
    name: 'specialMaInfoDetail',
    components: {
        layout, layoutRight
    },
    data() {
        return {
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        topic_id: +this.$route.params.id,
                        page: 1,
                        pageSize:3,
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
                // 相关视频
                video: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        limit: 4,
                        topic_id: this.$route.params.id,
                        page: 1,
                        committee_id
                    },   // 参数条件
                },
                // 相关指南
                guideDownload: {
                    show: true, // 是否显示
                    title: '相关指南', // 标题
                    type: 'ma',
                    methods: 'post',
                    moreLink: '/special-ma/guide-list/'+this.$route.params.id,
                    detailLink: '/special-ma/guide-detail/'+this.$route.params.id,
                    data: {
                        limit: 5,
                        topic_id: this.$route.params.id,
                        page: 1,
                        committee_id
                    },   // 参数条件
                }
            },
            newId: this.$route.query.id,
            type: this.$route.query.type,
            // newId:'20282',
            // 文章详情
            infomation: {},
            next_news:null,
            pre_news:null,
            next: false,
            loading: true,
            newss: '',
            userUid: [],
            followList: [],
            isGuide: false,
            meetingId: '',
            guideId: 0,
            correlationList: [], //相关资讯
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        $route(to, from) {
            if (to.fullPath != from.fullPath) {
                this.newId = to.query.id
                this.type = to.query.type
                this.getInfoDetail()
            }

        }
    },
    created() {
        this.getInfoDetail()
    },
    methods: {
        expertJumpDetail,
        handleFollowEvent(e) {
            let id = e.target.id
            if (id && id.indexOf('follow-btn-') > -1) {
                let index = id.lastIndexOf("-");
                index = id.substr(index + 1, id.length)
                if (this.followList[index] == 0) {
                    this.request.post('FollowExperts', { experts_uid: this.userUid[index] }).then(res => {
                        if (res.data.code == 200) {
                            this.getInfoDetail()
                        }
                    })
                }
            }

        },
        getInfoDetail() {
            this.loading = true
            let data = {
                new_id: this.newId,
                type:this.type,
                topic_id: this.$route.params.id,
                topic_committee_id:committee_id
            }
            this.request.post('GetNewsDetail', data).then(res => {
                res.data.pre_news && (this.pre_news = res.data.pre_news)
                res.data.next_news && (this.next_news = res.data.next_news)
                if (res.data && res.data != '') {
                    this.infomation = res.data
                    let { news_content, expert_avatar, expert_user_id, expert_org, expert_name } = this.infomation;
                    let regx = /\[\[\[.*?\]\]\]/g;
                    let gzbtn = ''
                    let newss = ''
                    // let news = '[[[expert-person]]][[[expert-person="cDvwdYms1M3r9wSD1Gr2qQ_d_d"]]][[[expert-person="hWCXEiM_xNWqGZskDnzTd8A_d_d"]]]'
                    news_content = news_content.replace(/\/search-pages\/roster\/roster/g,'/#/job-warehouse/rollCall');
                    this.infomation.news_content = news_content
                    let userList = this.infomation.news_content.match(regx)
                    
                    if (userList && userList.length > 0) {
                        for (var i = 0; i < userList.length; i++) {
                            let index = i
                            let hasUid = userList[i].indexOf("=")
                            if (hasUid != -1) {
                                userList[i] = userList[i].match(/"(\S*)"/)[1]
                                this.userUid[i] = userList[i]
                            } else {
                                this.userUid[i] = expert_user_id
                            }
                            
                            this.request.post('ExpertsData', { experts_uid: this.userUid[i] }).then(res => {
                                this.followList[index] = res.data.fans_status
                                let { avatar, real_name, org_cnname, user_id } = res.data
                                if (avatar == '') {
                                    avatar = require('@/assets/images/person-default.png')
                                }
                                if (!this.followList[index]) {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #c00000;padding:2px 10px;border-radius:5px;color:#c00000;cursor:pointer;">+关注</span>'
                                } else {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #cccccc;padding:2px 10px;border-radius:5px;color:#cccccc;">已关注</span>'
                                }
                                let origin = window.location.origin;
                                newss = '<div style="width: 100%;height: 80px;border: 1px solid #eeeeee;border-radius:10px;" class="info-item flex x-between mb20 pt5 pb5 pl10 pr10 y-center"><div class="flex x-left y-center"><img style="width: 70px;height: 70px;object-fit: cover;object-position: top;border-radius: 50%;float:left;" class="info-item-avatar" src=' + avatar + ' alt="" /><div style="height: 50px;float:left;margin-left:20px;margin-top:10px;" class="info-item-text flex-column x-between t-l ml20"><p style="margin-bottom:0;"><span class="color000 fs14">' + real_name + '</span>' + gzbtn + '</p><span class="color999 fs12">' + org_cnname + '</span></div></div><a href=/#/special-ma/user-detail/'+this.$route.params.id+'?uid=' + user_id + ' style="width: 100px;height: 30px;border-radius: 20px;border: 1px solid #3c56d3;color: #3c56d3;cursor: pointer;" class="info-item-btn t-c fs12 flex y-center x-center">前往专家主页</a></div>'
                                if (hasUid != -1) {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person="' + userList[index] + '"]]]', newss)
                                } else {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person]]]', newss)
                                }
                                this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                                    videoObject.init(el, vid);
                                })
                            })
                        }
                    } else {
                        this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                            videoObject.init(el, vid);
                        })
                    }
                    // 相关资讯，查询条件待定
                    this.getCorrelationNews(res.data.news_id)
                } else {
                    this.infomation = {}
                }
                if (this.next) {
                    this.$router.replace({
                        path: '/special-ma/infomation-detail/'+this.$route.params.id,
                        query: {
                            id: this.newId,
                            type: this.type
                        }
                    }, () => { })
                }

                this.loading = false
            })
        },
        getCorrelationNews(id){
            this.request.post('GetNews',{
                limit: 3, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            }).then(res => {
                if(res.data.count > 1){
                    let list = res.data.list
                    list.forEach((item,index)=>{
                        if(item.id == id){
                            list.splice(index, 1)
                        }
                        
                    })
                    this.correlationList = list
                }
            })
        },
        // 点赞收藏
        getExeLikeCollect(operate) {
            this.request.post('GetExeLikeCollect', {
                id: this.infomation.id,
                type: 2,
                operate
            }).then(res => {
                // 收藏1 点赞2
                if (operate == 1) {
                    let is_collect = this.infomation.is_collect
                    if (is_collect) {
                        is_collect = 0
                    } else {
                        is_collect = 1
                    }
                    this.infomation.is_collect = is_collect
                } else {
                    let is_like = this.infomation.is_like
                    if (is_like) {
                        is_like = 0
                    } else {
                        is_like = 1
                    }
                    this.infomation.is_like = is_like
                }
            })
        },
        // 上一条下一条
        handleNextNews(id) {
            this.newId = id
            this.next = true
            this.pre_news = null
            this.next_news = null
            this.getInfoDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.guide-list-left {
    width: 770px;

    .guidelist-breadcrumb {
        width: 300px;
        height: 20px;

        .guide-ant-breadcrumb {
            float: left;
            color: #c1c1c1;
        }
    }

    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #d8d8d8;

        p {
            margin-bottom: 0;
        }
    }

    .content-art {
        width: 100%;

        /deep/ img {
            max-width: 100%;
            height: auto;
        }
    }

    .guidelist-download {
        width: 100%;
        height: 44px;
        cursor: pointer;

        .color-member {
            color: #1e51c9;
        }

        .download-read {
            width: 104px;
            height: 44px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
        }

        .download-read-new {
            height: 40px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            padding: 0 10px 0 10px;
        }
    }

    .guidelist-banner {
        width: 100%;
        height: 187px;
    }

    .med-shoucang1,
    .med-dianzan {
        color: #FFA45D;
    }

    .cursor {
        cursor: pointer;
    }

    .correlation-box {
        width: 100%;
        height: auto;
        border: 1px solid #1E51C9;
        padding: 10px;

        .correlation-bg {
            width: 100%;
            height: auto;
            background: #f6f8fc;
            padding: 10px;

            .correlation-title {
                border-bottom: 1px solid #1E51C9;
                display: inline-block;
                line-height: 30px;
            }
        }
    }
}

.guide-list-right {
    width: 398px;
}

@media screen and (max-width:768px) {
    .infomation-detail {
        .container {
            display: block;
            padding: 0 10px;
        }

        .infomation-mobile-bread {
            display: none;
        }

        .guide-list-left,
        .guide-list-right {
            width: 100%;
        }
    }
}</style>
